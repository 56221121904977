import { KeyIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';

const PasswordField = ({ value, ...properties }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    return (
        <div className={`flex h-14 w-full items-center rounded-lg border border-solid bg-transparent text-black ${isFocused ? 'border-primary-500' : 'border-grey-200'} bg-white focus:outline-none`}>
            <input className={'size-full bg-transparent px-4'} type={'password'} value={value} onFocus={handleFocus} onBlur={handleBlur} {...properties} />

            <div className={`flex h-full items-center justify-center border-0 border-l border-solid px-4 ${isFocused ? 'border-primary-500' : 'border-grey-200'}`}>
                <KeyIcon className={`aspect-square h-5 ${isFocused ? 'text-primary-500' : 'text-grey-400'}`} />
            </div>
        </div>
    );
};

export default PasswordField;
